export default {
  locationsPage: {
    '.locationsContainer': {
      margin: ['0.25rem 0rem', '', '0.5rem 0.25rem']
    },
    '.logo': {
      height: 'unset',
      position: 'static',
      transform: 'unset',
      padding: ['0.5rem', '', '1rem'],
      backgroundColor: 'black',
      img: {
        maxHeight: ['60px', '75px'],
        marginRight: ['8rem', '8rem', '2rem', '2rem']
      },
      '.olo-link': {
        alignSelf: 'center',
        position: 'absolute',
        right: ['16px', '24px', '36px', '50px', '60px'],
        border: '2px solid white',
        padding: '.8rem .6rem',
        transition: '.3s',
        textTransform: 'uppercase',
        fontWeight: '600',
        ':hover': {
          backgroundColor: 'white',
          color: 'black'
        }
      }
    },
    '.location': {
      margin: '0.25rem 0.5rem'
    },
    '.CTAButton': {
      display: 'none'
    },
    '.locationTitle': {
      marginBottom: '1rem',
      fontSize: '2rem'
    },
    '.enterSiteButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      position: 'relative',
      backgroundColor: 'transparent',
      padding: ['0.5rem', '0.5rem', '1rem', '2rem 1rem']
    },

    '.smallNavMenu': {
      '.react-reveal': {
        display: 'flex'
      },
      ' .navItemDropdown': {
        width: '100%!important',
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.react-reveal': {
        display: 'flex'
      },
      '.navItem': {
        color: 'secondary',
        padding: '0rem',
        a: {
          padding: '0.5rem',
          fontSize: ['1rem', '1rem', '1rem', '0.9rem', '1rem']
        }
      },
      '.navItemDropdown': {
        boxShadow: 'inset 2px 2px 1px white, inset -2px -2px 1px white'
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      width: '100%',
      padding: '0rem',
      flexGrow: '1',

      a: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: ['0.75rem', '0.75rem', '1rem'],
        ':hover': {
          color: 'light'
        }
      },
      ':hover': {
        color: 'light'
      }
    },
    '.hamburger': {
      borderColor: 'secondary',
      '> div': {
        backgroundColor: 'secondary'
      }
    },

    '.hamburgerOpen': {
      borderColor: 'secondary',
      '> div': {
        backgroundColor: 'secondary'
      }
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        filter: 'unset',
        maxHeight: ['55px', '', '100px', '']
      }
    },

    '.navMenu': {
      left: '-100vh',
      right: 'unset'
    },

    '.navMenuOpen': {
      backgroundColor: 'primary',
      width: ['90%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      padding: '0rem',
      '.navItemDropdown': {
        flex: '1',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      '.navItemDropdownMenu': {
        position: 'static',
        width: 'fit-content',
        backgroundColor: 'transparent',
        textAlign: 'center',
        '.navItem': {
          a: {
            fontSize: ['1rem', '1rem', '1rem', '1rem'],
            padding: '0.5rem',
            margin: 'auto'
          }
        }
      },
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0rem'],
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem']
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      left: '-300vw'
    },

    '.navItemDropdownMenu': {
      width: 'fit-content',
      backgroundColor: 'background'
    },

    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: '#4d442d',
      paddingBottom: '1rem',
      display: 'none'
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem']
      },
      margin: '0rem',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'light'
        }
      }
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: '#c6a34d'
      }
    },
    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      img: {
        opacity: '1',
        filter: 'unset',
        maxHeight: ['55px', '55px']
      }
    },
    '.logoScrolled': {
      img: {
        filter: 'unset',
        opacity: '1',
        maxHeight: ['45px', '45px']
      }
    },

    '.locationSwitcher': {
      '.container': {
        padding: ['0rem', '0rem', '0rem', '0rem'],
        '.button': {
          fontSize: '0.8rem'
        },

        '.popUp': {
          transform: 'unset',
          width: '100%'
        },
        '.content': {
          backgroundColor: 'background'
        }
      }
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'black',
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'white'
    },
    '.multiButtonContainer': {
      variant: 'buttons.primary'
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2.5rem', '', '3rem', '4.5rem', '5rem'],
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bolder',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'secondary',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'body',
    fontSize: ['1rem', '1.5rem', '2rem', '2.5rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    overflow: 'hidden',
    // margin: ['1rem', '', '', '2rem'],
    backgroundColor: '#28281f',
    padding: ['2rem 1rem', '', '2rem', '3rem'],
    '.content': {
      order: ['', '', '2'],
      width: ['', '', '50%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: '1',
      width: ['', '', '50%'],
      height: ['', '', '70vh'],
      '::before': {
        background: 'unset'
      },
      img: {
        // height: '100vh',
        border: 'solid 10px',
        borderColor: 'secondary'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    overflow: 'hidden',
    flexDirection: ['', '', 'row'],
    backgroundColor: '#28281f',
    padding: ['2rem 1rem', '2rem', '4rem 3rem', '5rem 3rem'],
    '.imageContainer': {
      order: '2',
      width: ['', '', '60%'],
      height: ['', '', '95vh'],
      position: 'relative',
      '::before': {
        content: ['none', 'none', "''"],
        border: 'solid 3px',
        borderColor: 'white',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%',
        // boxShadow: '20px -20px gold',
        borderRadius: '300px 0px 300px 100px'
      },
      img: {
        // height: '100vh',
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%'],
        boxShadow: '20px -20px gold',
        border: 'solid 10px white'
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%'],
        boxShadow: '20px -20px gold',
        border: 'solid 10px white'
      }
    },

    '.content': {
      order: '2',
      width: ['', '', '40%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem 1.5rem 1rem', '2.5rem 4.5rem 2.5rem 1rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: ['100vh', '', '70vh'],
    '.section': {
      margin: 'auto auto',
      padding: '0.5rem'
    },
    '.hero_content_container': {
      width: ['100%', '', '100%'],
      maxWidth: 'unset',
      alignItems: 'center',
      textAlign: 'center',
      margin: 'auto',
      transition: 'all ease-in-out 0.8s',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '3rem', '4rem', '5rem', '6rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'white',
      padding: '1rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.5rem', '2rem', '2.5rem', '3rem', '3.5rem'],
      width: '100%',
      textAlign: 'center',
      color: 'secondary',
      width: '100%',
      justifyContent: 'center'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.slick-slider': {
      height: ['100vh', '', '70vh'],
      '.slick-slide > div': {
        height: ['100vh', '', '70vh']
      },
      '.slick-prev, .slick-next': {
        // display: 'none !important',
        backgroundColor: 'secondary',
        color: 'white',
        height: '40px',
        width: '40px'
      },
      '.slick-prev': {
        left: '0px'
      },
      '.slick-next': {
        right: '0px'
      },
      '.slick-slide img': {
        height: ['100vh', '', '70vh'],
        filter: 'brightness(0.75)',
        transition: 'all ease-in-out 1.5s'
      }
    },
    ':hover': {
      '.hero_content_container': {
        opacity: '0',
        transform: 'translate(-200%,-200%)'
      },
      '.slick-slide img': {
        height: ['100vh', '', '70vh'],
        filter: 'brightness(1.1)'
      }
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.imageContainer': {
      order: ['2', '', '2']
    },
    // maxHeight: ['', '', '50vh'],
    '.content': {
      order: ['1', '1'],
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem',
      // display: 'none',
      fontWeight: 'normal',
      border: 'none',
      // borderBottom: '2px solid',
      // borderColor: 'secondary',
      width: 'fit-content',
      maxWidth: 'unset',
      fontSize: ['1.75rem', '2rem', '2.5rem', '3rem', '4rem']

      // fontSize: '0rem',
      // color: 'background',
      // '::before': {
      //   variant: 'customVariants.title',
      //   content: "'Recent '", // ! bad coding but could't be bothered to shadow
      // },
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset',
      color: 'secondary',
      fontWeight: 'bold'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'white',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageQuote1: {
    padding: ['20vh 1rem', '', '', '20vh 2rem'],
    textAlign: 'left',
    alignItems: 'flex-start',

    '.title': {
      fontSize: ['3rem', '4rem', '7rem', '10rem'],
      color: '#404040',
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    '.subtitle': {
      fontSize: ['3rem', '4rem', '7rem', '10rem'],
      color: '#505037',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      borderBottom: '15px solid',
      borderColor: 'secondary',
      paddingLeft: '10%'
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGiftCards: {
    padding: '20vh',
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageQuote2: {
    variant: 'customVariants.homepageQuote1',
    '.subtitle': {
      fontSize: ['1.5rem', '2.5rem', '3rem', '4rem'],
      color: '#505037',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      borderBottom: '15px solid',
      borderColor: 'secondary',
      paddingLeft: '10%',
      textAlign: 'center',
      paddingBottom: '2rem'
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageLocation: {
    // variant: 'customVariants.sideBySide1',
    padding: ['5rem 1rem', '', '10rem 1rem'],
    '.section': {
      maxWidth: 'unset'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  aboutOwner1: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      width: ['', '', '70%'],
      padding: ['2rem', '', '', '1.5rem 2rem', '2.5rem 2rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '30%'],
      height: ['', '', '70vh'],
      '::before': {
        background: 'unset'
      },
      img: {
        // height: '100vh',
        border: 'solid 10px',
        borderColor: 'secondary'
      }
    }
  },

  aboutOwner2: {
    variant: 'customVariants.aboutOwner1'
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    border: 'solid 1px black',
    padding: ['1rem 0rem', '1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'background',
    color: 'white',
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      color: 'secondary',
      ':hover': {
        backgroundColor: 'secondary',
        color: 'black',
        opacity: '1'
      }
    },
    '.menuItemInnerContainer': {
      margin: '0rem',
      padding: ['', '', '1rem']
    },

    '.menuItemContentContainer': {
      width: '100%',
      padding: ['0.5rem', '1rem']
    },

    '.menuSectionTitle': {
      textTransform: 'uppercase',
      color: 'text',
      margin: ['2rem 1rem', '', '1rem 1.5rem'],
      padding: ['1rem 0rem', '1rem'],
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2rem'],
      borderBottom: 'solid 3px',
      borderColor: 'secondary'
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      textAlign: 'left',
      margin: ['1rem 1rem 2rem', '', '1rem 1.5rem'],
      color: 'secondary',
      padding: ['0rem', '1rem']
    },

    '.menuItemImageContainer': {
      width: '150px',
      height: '150px'
    },
    '.menuItemName': {
      color: 'text',
      fontFamily: 'heading',
      fontWeight: '500',
      fontSize: ['1.2rem', '1.3rem', '', '']
    },

    '.menuItemDescription': {
      color: 'secondary',
      fontSize: ['0.8rem', '0.9rem'],
      width: '100%'
    },
    '.menuPriceContainer': {
      fontFamily: 'heading',
      // display: 'none',
      color: 'light'
    },
    '.menuItemPrice': {
      color: 'light'
    },
    '.variantsContainer': {
      color: 'light'
      // display: 'none',
    },

    '.menuItemImg': {
      objectFit: 'contain',
      objectPosition: 'top'
    },
    '.menuItemImageContainer': {
      width: '100%',
      maxWidth: '50%',
      maxHeight: 'unset',
      height: 'auto',
      marginBottom: '1.5rem'
    },

    '#sec-Akjzs9C2QdG7O9dqkO2cqg': {
      textAlign: 'center',

      '.menuItemInnerContainer': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      },
      '.menuItemImg': {
        objectFit: 'contain'
      },
      '.menuItemImageContainer': {
        width: '100%',
        maxWidth: 'unset',
        maxHeight: 'unset',
        height: 'auto',
        marginBottom: '1.5rem'
      },
      '.menuItemContainerImgActive': {
        width: ['100%', '75%', '33.3%', '33.3%']
      },
      '.menuSectionTitle': {
        textAlign: 'center'
      },
      '.menuItemsContainer': {
        justifyContent: 'center'
      }
    }
  },

  specialsMenu: {
    variant: 'customVariants.menu',
    textAlign: 'center',

    '.menuItemInnerContainer': {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    '.menuItemImg': {
      objectFit: 'contain'
    },
    '.menuItemImageContainer': {
      width: '100%',
      maxWidth: 'unset',
      maxHeight: 'unset',
      height: 'auto',
      marginBottom: '1.5rem'
    },
    '.menuItemContainerImgActive': {
      width: ['33.3%', '33.3%', '33.3%', '33.3%']
    },
    '.menuSectionTitle': {
      textAlign: 'center'
    },
    '.menuItemsContainer': {
      justifyContent: 'center'
    }
  },

  // ? ========================
  // ? ====  Party page  ====
  // ? ========================

  partyPackages: {
    variant: 'customVariants.menu',
    '.menuItemPriceVariants': {
      '::before': {
        content: "'$'"
      }
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'white',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: '#c6a34d',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'white'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: '#c5c2c2',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.subtitle': {
      color: '#5d574e'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
