export default {
  pageHeroV1: {
    container: {
      height: '60vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['1.75rem', '', '2.5rem'],
        marginBottom: 1,

        textTransform: 'uppercase',
        color: 'white'
      }
    }
  }
}
